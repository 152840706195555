import React from "react";
import "../style/components/_customer.scss";

function Customers({ images }) {
  const rows = 3;
  const grid = [];

  for (let i = 0; i < images.length; i += rows) {
    const rowItems = images.slice(i, i + rows);
    grid.push(
      <tr key={i}>
        {rowItems.map((img, index) => (
          <td key={index}>
            <img className="logos" src={img} alt="고객사 로고" />
          </td>
        ))}
      </tr>
    );
  }

  return (
    <table className="customers-table">
      <tbody>{grid}</tbody>
    </table>
  );
}

export default Customers;
