import React from "react";

// Components
import Header from "../components/Header";
import Title from "../components/PageTitle";
import Footer from "../components/Footer";

// Style
import "../style/pages/_contact.scss";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faFax,
  faMapMarkerAlt,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";

function Contact() {
  const title = "고객문의";

  return (
    <div className={"contact-page"}>
      <Header />
      <Title title={title} />
      <div className="company-page">
        <div className="company-info">
          <h2>회사 정보</h2>
          <div className="contact-item">
            <FontAwesomeIcon icon={faEnvelope} className="icon" />{" "}
            <span className="text">
              &#x79;&#x65;&#x6F;&#x6E;&#x67;&#x6E;&#x61;&#x6D;&#x32;&#x30;&#x38;&#x33;&#x40;&#x67;&#x6D;&#x61;&#x69;&#x6C;&#x2E;&#x63;&#x6F;&#x6D;
            </span>
          </div>
          <div className="contact-item">
            <FontAwesomeIcon icon={faMobileAlt} className="icon" />{" "}
            <span className="text">010-5294-2083</span>
          </div>
          <div className="contact-item">
            <FontAwesomeIcon icon={faPhone} className="icon" />{" "}
            <span className="text">032) 672-2083</span>
          </div>
          <div className="contact-item">
            <FontAwesomeIcon icon={faFax} className="icon" />{" "}
            <span className="text">032) 684-4568</span>
          </div>
          <div className="contact-item">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />{" "}
            <span className="text">경기 부천시 석천로 394-13 영남산업</span>
          </div>

          <div className="divider"></div>
          <div className="contact-details">
            <p>
              분체도장/실크인쇄에 관한 견적이나 기타 문의 사항이 있으시면
              언제든지 연락 주시기 바랍니다. 저희는 고객님의 요구사항을 정확히
              파악하고, 전문적인 상담을 통해 최적의 해결책을 제공해
              드리겠습니다.
            </p>
          </div>
        </div>
        <div className="map-container">
          <h2>찾아오시는 길</h2>
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3164.4123481884612!2d126.76904900000001!3d37.521776!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c82a15585df4d%3A0x340b17ca5f83d9fc!2z7JiB64Ko67aE7LK064-E7J6l!5e0!3m2!1sko!2skr!4v1704619301403!5m2!1sko!2skr"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen={true}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
