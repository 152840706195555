import React from "react";
import "../../style/components/quailty/_youth.scss";
import Customers from "../Customers";

// Logos
import moqous from "../../assets/customer_logo/moqous.png";
import keti from "../../assets/customer_logo/keti.png";
import thordrive from "../../assets/customer_logo/thordrive.png";

const YouthFriend = () => {
  const logo_images = [moqous, keti, thordrive];

  return (
    <div className="youth">
      <h2>청년친화적 경영</h2>
      <p>
        창업하는 청년에게 보내는 응원과 더불어, 영남분체도장과 함께할 날을
        기대합니다. 영남분체도장은 고품질의 분체도장 서비스와 전문 지식을 갖춘
        업체로, 고객 만족을 위해 최선을 다하고 있습니다. 청년업체와 협력하여 더
        나은 제품을 만들고, 시장에서 성공적으로 경쟁하기 위해 함께 노력하고
        있습니다. 영남분체도장은 청년들과 함께 성장할 수 있는 기회를 기대하고
        있습니다.
      </p>
      <Customers images={logo_images} />
    </div>
  );
};

export default YouthFriend;
