import { useState } from "react";

// Components
import Header from "../components/Header";
import Title from "../components/PageTitle";
import Products from "../components/Products";
import Footer from "../components/Footer";

// Style
import "../style/pages/_services.scss";

function Services() {
  const title = "제품정보";
  const [selected, setSelected] = useState("all");

  const handleSelectClick = (event) => {
    const value = event.currentTarget.getAttribute("value");
    setSelected(value);
  };

  return (
    <div className="service-page">
      <Header />
      <Title title={title} />
      <div className="service-page__options">
        <div
          value="all"
          onClick={handleSelectClick}
          className={`option ${selected === "all" ? "selected" : ""}`}
        >
          전체보기
        </div>
        <div
          value="semiconductor"
          onClick={handleSelectClick}
          className={`option ${selected === "semiconductor" ? "selected" : ""}`}
        >
          반도체기기
        </div>
        <div
          value="homeAppliances"
          onClick={handleSelectClick}
          className={`option ${
            selected === "homeAppliances" ? "selected" : ""
          }`}
        >
          가전기기
        </div>
        <div
          value="video"
          onClick={handleSelectClick}
          className={`option ${selected === "video" ? "selected" : ""}`}
        >
          영상장비
        </div>
        <div
          value="equipment"
          onClick={handleSelectClick}
          className={`option ${selected === "equipment" ? "selected" : ""}`}
        >
          의료기기
        </div>
        <div
          value="variousDevices"
          onClick={handleSelectClick}
          className={`option ${
            selected === "variousDevices" ? "selected" : ""
          }`}
        >
          기타
        </div>
      </div>
      <Products category={selected} />
      <Footer />
    </div>
  );
}

export default Services;
