import React, { useState } from "react";
import "../style/components/_certifications.scss";

function Certifications({ images }) {
  const [selectedImg, setSelectedImg] = useState(null);

  const rows = 3;
  const grid = [];

  for (let i = 0; i < images.length; i += rows) {
    const rowItems = images.slice(i, i + rows);
    grid.push(
      <tr key={i}>
        {rowItems.map((img, index) => (
          <td key={index} onClick={() => setSelectedImg(img)}>
            <img
              src={img}
              alt="자격증"
              style={{ cursor: "zoom-in" }}
              onContextMenu={(e) => e.preventDefault()}
            />
          </td>
        ))}
      </tr>
    );
  }

  return (
    <>
      <table className="customers-table">
        <tbody>{grid}</tbody>
      </table>
      {selectedImg && (
        <div className="modal" onClick={() => setSelectedImg(null)}>
          <span className="close">&times;</span>
          <img
            className="modal-content"
            src={selectedImg}
            alt="자격증 확대"
            onContextMenu={(e) => e.preventDefault()}
          />
          {/* <div className="caption">자격증 확대 이미지</div> */}
        </div>
      )}
    </>
  );
}

export default Certifications;
