import React from "react";
import { useNavigate } from "react-router-dom";
import "../style/components/_sidebar.scss";
import { useRecoilState } from "recoil";
import { sidebarState } from "../states/sidebarState";

function Sidebar() {
  const [sidebarOpen, setSidebarOpen] = useRecoilState(sidebarState);
  const navi = useNavigate();

  const handleCloseClick = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleNavClick = (event) => {
    const path = event.currentTarget.getAttribute("data-path");

    setSidebarOpen(!sidebarOpen);
    navi(path);
  };

  return (
    <div className={`sidebar ${sidebarOpen ? "open" : "close"}`}>
      <div className="sidebar-right">
        <div className="sidebar-content">
          <div className="close--btn" onClick={handleCloseClick}>
            &times;
          </div>
          <nav>
            <div data-path="/" onClick={handleNavClick}>
              홈
            </div>
            <div data-path="/services" onClick={handleNavClick}>
              제품정보
            </div>
            <div data-path="/quality" onClick={handleNavClick}>
              품질관리
            </div>
            <div data-path="/about" onClick={handleNavClick}>
              회사소개
            </div>
            <div data-path="/contact" onClick={handleNavClick}>
              고객문의
            </div>
          </nav>
        </div>
      </div>
      <div className="sidebar-left" onClick={handleCloseClick}></div>
    </div>
  );
}

export default Sidebar;
