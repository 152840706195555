import React from "react";
import "../style/components/_pagetitle.scss";

function PageTitle(props) {
  return (
    <div className="TitleImage">
      <div className="image-container">
        <p className="title-overlay">{props.title}</p>
      </div>
    </div>
  );
}

export default PageTitle;
