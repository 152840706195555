// React
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Style
import "../style/components/_header.scss";

// State
import { useRecoilState } from "recoil";
import { sidebarState } from "../states/sidebarState";

// Component
import Sidebar from "./Sidebar.js";

// Logo and icons
import logo from "../assets/header/header_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

function Header() {
  const [sidebarOpen, setSidebarOpen] = useRecoilState(sidebarState);
  const [shouldRenderSidebar, setShouldRenderSidebar] = useState(false);

  const handleCloseClick = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const navigate = useNavigate();

  const handleNavClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    if (sidebarOpen) {
      setShouldRenderSidebar(true); // Sidebar 랜더링
    } else {
      // 애니메이션 종료 후 Sidebar 숨기는 로직
      setTimeout(() => {
        setShouldRenderSidebar(false);
      }, 300);
    }
  }, [sidebarOpen]);

  return (
    <div className="head">
      <header className={`header`}>
        <div
          className="header__logo"
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={logo} alt="회사 로고" />
        </div>
        <nav className="header__nav">
          <div className="nav-group">
            <div onClick={() => handleNavClick("/services")}>제품정보</div>
            <div onClick={() => handleNavClick("/quality")}>품질관리</div>
            <div onClick={() => handleNavClick("/about")}>회사소개</div>
            <div onClick={() => handleNavClick("/contact")}>고객문의</div>
          </div>
        </nav>
        <div className="header--sidebar">
          <div onClick={handleCloseClick}>
            <FontAwesomeIcon icon={faBars} />
          </div>
        </div>
        {shouldRenderSidebar && <Sidebar />}
      </header>
    </div>
  );
}

export default Header;
