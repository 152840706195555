// Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import Title from "../components/PageTitle";
import FAQList from "../components/quality/FAQList";
import Certifications from "../components/Certifications";

// Style
import "../style/pages/_quality.scss";

// Certification
import puri from "../assets/certification/puri.jpg";
import iso9001 from "../assets/certification/iso9001.png";
import ssq from "../assets/certification/ssq.png";

// Process Images
import shot from "../assets/quality/shot.jpg";
import close_shot from "../assets/quality/close_shot.jpg";
import model1 from "../assets/quality/model_1.jpg";
import model2 from "../assets/quality/model_2.jpg";
import model3 from "../assets/quality/model_3.jpg";
import model4 from "../assets/quality/model_4.jpg";
import coating from "../assets/quality/coating.png";
import silk from "../assets/quality/silk.jpeg";
import thickness from "../assets/quality/thickness.jpg";
import box_manage from "../assets/quality/box_manage.jpg";
import YouthFriend from "../components/quality/YouthFriend";
import ColorOption from "../components/quality/ColorOption";
import InfosSecond from "../components/quality/InfosSecond";
import InfosFirst from "../components/quality/InfosFirst";
import InfosThird from "../components/quality/InfosThird";

function Quality() {
  const title = "품질관리";

  const images = [puri, iso9001, ssq];
  const colorModels = [
    { src: model1, description: "검정" },
    { src: model2, description: "노랑" },
    { src: model3, description: "초록" },
    { src: model4, description: "빨강" },
  ];

  const silk_info =
    "실크 인쇄는 고급 인쇄 기술 중 하나로, 다양한 금속 제품에 그림이나 디자인을 인쇄하는 데 사용합니다. 이 기술은 고해상도로 세밀한 디테일과 선명한 색상을 전달하는 데 사용하며, TV, 반도체 부품, 의료기기 외관과 같은 다양한 금속 제품에 적용할 수 있습니다. 영남분체도장은 고객의 요구에 부합하는 품질 높은 인쇄 솔루션을 제공합니다.";

  const coat_info =
    "분체도장은 제품 표면에 분말 도료를 분사하여 고품질의 마감을 부여하는 공정 기술입니다. 이 기술은 균일한 색상과 마감을 제공하며, 내구성과 내식성을 향상시킵니다. 다양한 분체도료로 다양한 효과와 스타일을 구현할 수 있습니다. 분체도장은 친환경적이며 휘발성 유기 화합물(VOC) 배출을 줄이는 장점이 있습니다. 우리 회사는 분체도장 분야의 전문가로서 고객의 요구를 충족시키고 최신 기술을 활용해 서비스를 제공합니다.";

  return (
    <div className="quality-page">
      <Header />
      <Title title={title} />
      <div className="quality-page__cont">
        <div className="quality-page__group">
          <div className="quality">
            <h2>영남분체도장의 품질</h2>
            <div className="hashtag">
              <div className="hashtag__item">#뿌리기업</div>
              <div className="hashtag__item">#35년 경력</div>
              <div className="hashtag__item">#ISO9001</div>
              <div className="hashtag__item">#SSQ인증</div>
            </div>
            <div className="picture__info">
              <div>
                <img
                  className="picture__info__image"
                  src={box_manage}
                  alt="창고 관리 그림"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </div>
              <div>
                <p className="picture__info__explain">
                  금속 도장 기술의 선구자 기업인 영남분체도장은 시각적 편안함,
                  쾌적함을 갖춘 다양한 스타일의 가정용 및 상업용 제품을
                  생산합니다.
                </p>
              </div>
            </div>
            <div className="picture__info">
              <div>
                <img
                  className="picture__info__image"
                  src={thickness}
                  alt="도막 두께 측정 그림"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </div>
              <div>
                <p className="picture__info__explain">
                  금속 부품의 강도와 내마모성을 향상시키는 여러 가지 분체도장
                  마감재를 제공합니다. 분체도장은 모든 금속과 호환되며 유광 또는
                  무광택 광택으로 제공됩니다.
                </p>
              </div>
            </div>
          </div>
        </div>
        <InfosSecond
          header={"분체도장이란"}
          image={coating}
          explain={coat_info}
        />

        <ColorOption models={colorModels} />

        <InfosThird
          header={"분체 도장 예시"}
          image1={shot}
          image2={close_shot}
        />

        <InfosFirst header={"실크인쇄란"} image={silk} explain={silk_info} />

        <div className="quality-page__group assurance">
          <h2>품질 보증(QA)</h2>
          <Certifications images={images} />
        </div>

        <FAQList />
        <YouthFriend />
      </div>
      <Footer />
    </div>
  );
}

export default Quality;
