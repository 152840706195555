import React from "react";
import "../../style/components/quailty/_coloroption.scss";

const ColorOption = ({ models }) => {
  // models가 배열인지 확인하고, 배열이 아닐 경우 빈 배열을 사용
  const validModels = Array.isArray(models) ? models : [];

  return (
    <div className="color__component">
      <h2>색상 옵션 예시</h2>
      <div className="color__option">
        {validModels.map((model, index) => (
          <div key={index}>
            <img
              src={model.src}
              alt={model.description}
              onContextMenu={(e) => e.preventDefault()}
            />
            <p>{model.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColorOption;
