import React from "react";
import "../../style/components/quailty/_infos.scss";

const InfosSecond = ({ header, image, explain }) => {
  return (
    <div className="info__component">
      <h2>{header}</h2>
      <div className="info__component__content">
        <div>
          <p className="info__component__explain">{explain}</p>
        </div>
        <div>
          <img
            src={image}
            alt="설명"
            className="info__component__image"
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
      </div>
    </div>
  );
};

export default InfosSecond;
