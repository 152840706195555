// React
import React from "react";

// Style
import "../style/components/_footer.scss";

// Logos
import yn_logo from "../assets/footer/logo-hori.png";
import puri_logo from "../assets/footer/puri.png";

function Footer() {
  return (
    <footer className="footer__wrap">
      <div className="footer">
        <div className="footer__info">
          <div>
            사업자 번호 : 130-34-64601 | 본사 : 경기 부천시 석천로 394-13 <br />{" "}
            대표번호 : 032-672-2083 | 010-5294-2083
            <br />
            이메일 : &#x79;&#x65;&#x6F;&#x6E;&#x67;&#x6E;&#x61;&#x6D;&#x32;&#x30;&#x38;&#x33;&#x40;&#x67;&#x6D;&#x61;&#x69;&#x6C;&#x2E;&#x63;&#x6F;&#x6D; | 팩스 : 032-684-4568
          </div>
          <p className="copyright">
            Copyright © 영남분체도장 All Right Reserved
          </p>
        </div>
        <div className="footer__links">
          <img src={puri_logo} alt="뿌리기업 로고" />
          <img src={yn_logo} alt="회사 로고" />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
