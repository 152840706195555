// React
import { useEffect, useRef } from "react";

// Style
import "../style/pages/_main.scss";

// Components
import Header from "../components/Header";
import Footer from "../components/Footer";

// Video
import mainVideo from "../assets/main/main.mov";

function Main() {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.75;
    }
  }, []);

  return (
    <div className="main-page">
      <Header />
      <div className="main-page__content">
        <video
          onContextMenu={(e) => e.preventDefault()}
          className="main-page__video"
          autoPlay
          loop
          muted
        >
          <source src={mainVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
