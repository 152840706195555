import React from "react";
import "../style/components/_products.scss";
import { productList } from "./ProductList.js";

function Products(props) {
  const category = props.category;

  function filterByTag(jsonData, tag) {
    if (tag === "all") {
      return jsonData;
    }

    return jsonData.filter((obj) => obj.Tags && obj.Tags.includes(tag));
  }

  const filteredProducts = filterByTag(productList, category);

  function categoryName(tag) {
    const mapping = {
      semiconductor: "반도체기기",
      electrical: "전기",
      homeAppliances: "가전기기",
      video: "영상장비",
      equipment: "의료기기",
      variousDevices: "기타기기",
    };

    return mapping[tag] || "알 수 없는 입력";
  }

  return (
    <div className="product__section">
      {filteredProducts.map((product) => (
        <div key={product.id}>
          <div className="product__info">
            <div className="product__category">
              <span>{categoryName(product.Tags)}</span>
              <h2>{product.ModelName}</h2>
            </div>
            {/* <div className="product__description">
              <p>{product.SummaryDescription}</p>
            </div> */}
            <div className="product__bottom">
              <div className="product__details">
                {product.DetailedDescription}
              </div>
              <div className="product__image">
                <img src={product.ImageURL} alt={product.ModelName} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Products;
