import React from "react";
import "../../style/components/quailty/_faq.scss";

const FAQList = () => {
  const faqs = [
    {
      key: "01",
      question: "분체도장은 언제 적용해야 하나요?",
      answer:
        "분체도장은 부품에 비용 관련하여 효율적이며 내구성이 뛰어난 마감 처리를 제공하여 부품의 수명과 열악한 환경에 관한 저항력을 높여줍니다. 예를 들어 해양 분야에 적합합니다.",
    },
    {
      key: "02",
      question: "분체도장의 가장 좋은 적용 분야는 무엇인가요?",
      answer:
        "분체도장은 금속 부품을 열과 냉기 손상, 부식을 포함한 실외 조건에 견딜 수 있게 해줍니다. 농기구, 산업 기계 및 사용 빈도가 높은 자동차 부품에 분체도장을 합니다.",
    },
    {
      key: "03",
      question: "분체도장의 내구성과 저항력은 어느 정도인가요?",
      answer:
        "분체도장 부품은 일반적으로 최대 20년 동안 내마모성을 유지합니다. 자외선 및 실외 환경에 지속해서 노출되거나 과도하게 사용하면 분체도장이 더 빨리 분해됩니다.",
    },
  ];

  return (
    <div className="faq-list">
      <h2>FAQ's</h2>
      <div>
        {faqs.map((faq) => (
          <div className="faq-item" key={faq.key}>
            <h2>{faq.question}</h2>
            <div className="faq-answer">{faq.answer}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQList;
