import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Title from "../components/PageTitle";
import Footer from "../components/Footer";
import "../style/pages/_notfound.scss";
import image404 from "../assets/notfound/404.png";

const NotFound = () => {
  const title = "404 에러";
  return (
    <div className="notfound-page">
      <Header />
      <Title title={title} />
      <div className="notfound__body">
        <img src={image404} alt="페이지를 찾을 수 없습니다" />
        <div className="notfound__content">
          <p>존재하지 않는 페이지입니다!</p>
          <p>
            홈으로 돌아가시려면{" "}
            <Link to="/" className="notfound__link">
              여기
            </Link>
            를 눌러주세요.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;
