import React from "react";
import "../../style/components/quailty/_infos.scss";

const InfosFirst = ({ header, image, explain }) => {
  return (
    <div className="info__component">
      <h2>{header}</h2>
      <div className="info__component__content">
        <div className="info__component__image">
          <img
            src={image}
            alt="설명"
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
        <div className="info__component__explain">
          <p>{explain}</p>
        </div>
      </div>
    </div>
  );
};

export default InfosFirst;
