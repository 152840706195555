// Components
import Header from "../components/Header";
import Title from "../components/PageTitle";
import Footer from "../components/Footer";
import Customers from "../components/Customers";

// Style
import "../style/pages/_about.scss";

// Logos
import ceragem from "../assets/customer_logo/ceragem.png";
import jeisys from "../assets/customer_logo/jeisys.png";
import samsung from "../assets/customer_logo/samsung.png";
import samsung_sdi from "../assets/customer_logo/samsung_sdi.png";
import skhynix from "../assets/customer_logo/skhynix.png";
import tvlogic from "../assets/customer_logo/tvlogic.png";
import cymechs from "../assets/customer_logo/cymechs.png";
import donga from "../assets/customer_logo/donga.png";
import semes from "../assets/customer_logo/semes.png";

function About() {
  const title = "회사소개";

  const images = [
    samsung,
    samsung_sdi,
    skhynix,
    ceragem,
    jeisys,
    tvlogic,
    cymechs,
    donga,
    semes,
  ];

  return (
    <div className="about-page">
      <Header />
      <Title title={title} />
      <div className="about-page__cont">
        <div className="about-page__group about">
          <h2>회사소개</h2>
          <div className="hashtag">
            <div className="hashtag__item">#뿌리기업</div>
            <div className="hashtag__item">#35년 경력</div>
            <div className="hashtag__item">#ISO9001</div>
            <div className="hashtag__item">#SSQ인증</div>
          </div>
          <p>
            영남분체도장은 1988년부터 분체도장과 실크인쇄 업무를 해오고
            있습니다. <span>공신력 있는</span> 고급도장 전문 업체이며,{" "}
            <span>뛰어난 품질</span>을 자랑합니다. 영남분체도장은 고품질을
            요구하는 제품만을 취급하고 있으며, 이를 다루는 기술과 품질을 모두
            갖추고 있는 회사입니다.
          </p>
        </div>

        <div className="about-page__group years">
          <h2>약력</h2>
          <ul>
            <li>2023년 6월 중소벤처기업부 지정 기업 </li>
            <li>2015년 4월 부천 삼정동 사옥 건립</li>
            <li>1992년 5월 부천 도당동 확장 이전</li>
            <li>1988년 4월 영남분체도장 설립</li>
          </ul>
        </div>

        <div className="about-page__group customer">
          <h2>우리 회사는 아래 기업들의 신뢰를 받고 있습니다</h2>
          <Customers images={images} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
