import bicyle from "../assets/products/bicyle.png";
import ceragem from "../assets/products/ceragem.png";
import donga from "../assets/products/donga.jpg";
import iris from "../assets/products/iris.png";
import jesis from "../assets/products/jesis.png";
import mopius from "../assets/products/mopius.png";
import theserif from "../assets/products/theserif.png";
import tvlogic from "../assets/products/tvlogic.png";
import vivarf from "../assets/products/vivarf.png";
import semi from "../assets/products/semi.png";
import samsung_sdi from "../assets/products/samsung_sdi.png";

export const productList = [
  {
    id: "01",
    Tags: ["homeAppliances"],
    ModelName: "삼성 더 세리프",
    SummaryDescription: "",
    DetailedDescription:
      "삼성 '더 세리프' TV 지지대는 우아한 미적 감각과 강력한 내구성을 제공합니다. 영남의 분체도장 기술은 시간이 지나도 변색 없이 깨끗함을 유지하며, 미세한 스크래치에 강합니다. 뛰어난 마감 처리로 고급스러운 분위기를 더하고, 인테리어의 완성도를 높여줍니다.",
    ImageURL: theserif,
  },
  {
    id: "10",
    Tags: ["semiconductor"],
    ModelName: "반도체 외장재",
    SummaryDescription: "",
    DetailedDescription:
      "반도체 외장재의 분체도장 과정은 정밀함을 요구합니다. 영남분체도장은 최신 장비와 철저한 품질 관리를 통해 도장의 일관된 품질을 보장하며, 고객은 항상 최고의 성능을 기대할 수 있습니다.",
    ImageURL: semi,
  },
  {
    id: "02",
    Tags: ["variousDevices"],
    ModelName: "모피엔스 항행보조장치",
    SummaryDescription: "",
    DetailedDescription:
      "모피엔스는 항행보조장치의 선도적인 제조업체입니다. 영남은 고품질 분체도장 서비스를 적용하여, 장비들에 일관적인 디자인을 제공합니다.",
    ImageURL: mopius,
  },
  {
    id: "03",
    Tags: ["video"],
    ModelName: "TV Logic 모니터",
    SummaryDescription: "",
    DetailedDescription:
      "TV Logic 모니터는 3G-SDI 및 다양한 비디오 포맷을 지원하는 전문가용 모니터입니다. 우리의 고급 분체도장 기술로 마감 처리된 이 제품은 높은 내구성과 우수한 품질을 자랑합니다. 견고한 구조는 현장에서의 다양한 환경에도 견딜 수 있도록 설계되었습니다. 우리의 도장 서비스는 제품의 수명을 연장하고, 어떠한 제작 환경에서도 깨끗하고 세련된 외관을 유지하게 해, TV Logic의 품질을 한층 더 높였습니다.",
    ImageURL: tvlogic,
  },
  {
    id: "04",
    Tags: ["equipment"],
    ModelName: "세라젬",
    SummaryDescription: "",
    DetailedDescription:
      "세라젬의 혁신적인 의료기기에 우리의 전문 분체도장을 적용함으로써, 제품의 내구성과 외관 품질을 한층 향상시켰습니다. 이 특별한 도장 처리는 제품의 수명을 연장시키고, 장시간 사용에도 색상과 마감의 변함없는 아름다움을 보장합니다. 우리의 도장 기술은 세라젬 제품이 완벽한 기능성과 함께 우수한 외관을 유지할 수 있도록 돕습니다.",
    ImageURL: ceragem,
  },
  {
    id: "05",
    Tags: ["equipment"],
    ModelName: "제이시스",
    SummaryDescription: "",
    DetailedDescription:
      "저희의 고품질 분체도장 기술로 제이시스 메디컬의 의료 기기의 견고함과 세련된 외관을 더욱 돋보이게 했습니다. 이 고급 도장 과정은 임상 사용 중에도 기기의 흰색이 오랫동안 깨끗하게 유지되도록 하며, 전문적인 기능성과 미적 요소가 완벽하게 조화를 이룹니다. 저희의 세심한 작업은 제이시스 메디컬의 장비가 의료 미용 분야에서 성능과 외형 모두에서 선두를 달리도록 힘을 보태고 있습니다.",
    ImageURL: jesis,
  },
  {
    id: "06",
    Tags: ["equipment"],
    ModelName: "VIVA 멀티 RF 발생기",
    SummaryDescription: "",
    DetailedDescription:
      "스타메드의 의료 기기에 저희 분체도장을 적용하여, 제품의 견고함과 세련된 미를 향상시켰습니다. 이 특수 도장은 장비의 내구성을 강화하며, 의료 환경에서의 지속적인 사용에도 뛰어난 품질을 유지할 수 있도록 해줍니다. 스타메드의 혁신적인 기술과 저희의 도장 서비스가 만나, 더욱 완벽한 의료 솔루션을 제공합니다.",
    ImageURL: vivarf,
  },
  {
    id: "07",
    Tags: ["video"],
    ModelName: "IRIS ID 홍채등록기",
    SummaryDescription: "",
    DetailedDescription:
      "분체도장 서비스가 적용된 Iris ID의 제품은, 그들의 첨단 기술에 완벽하게 어울리는 견고함과 미적 완성도를 자랑합니다. 이 도장 처리는 홍채 인식 장비의 내구성을 강화하고, 장기간에 걸쳐서도 그 견고함과 정밀함을 유지하도록 돕습니다. 홍채 인식 기술의 세계적인 선두 주자인 Iris ID의 제품에 저희 도장 기술을 적용함으로써, 신뢰성 높은 보안 솔루션을 보다 안전하고 효과적으로 사용할 수 있도록 이바지했습니다.",
    ImageURL: iris,
  },
  {
    id: "08",
    Tags: ["homeAppliances"],
    ModelName: "마커스 자전거",
    SummaryDescription: "",
    DetailedDescription:
      "마커스 전기자전거에 적용된 저희 분체도장은 강인함과 세련된 미를 한층 강조합니다. 이 도장은 제품의 내구성을 강화하고, 빈번한 사용에도 색상과 광택을 오랫동안 유지시켜 줍니다. 첨단 모빌리티 제품이 외부 환경에 강하면서도 아름다운 모습을 잃지 않도록, 저희의 기술력이 함께합니다.",
    ImageURL: bicyle,
  },
  {
    id: "09",
    Tags: ["variousDevices"],
    ModelName: "동아탱커",
    SummaryDescription: "",
    DetailedDescription:
      "동아탱커의 내부 장치에 적용된 저희 분체도장은, 바닷물을 염두에 둔 장치의 내식성을 향상시키고, 오랜 기간 환경에 견딜 수 있는 내구성을 제공합니다. 첨단 처리 기술을 가진 이 장치에 우리의 도장 기술이 더해져, 제품의 가치를 높이고 신뢰성을 강화하였습니다. ",
    ImageURL: donga,
  },

  {
    id: "11",
    Tags: ["variousDevices"],
    ModelName: "삼성SDI 베터리",
    SummaryDescription: "",
    DetailedDescription:
      "삼성SDI 베터리에 적용된 영남의 분체도장 기술은 뛰어난 마감과 장기간의 색상 보존을 보장합니다. 이 도장은 베터리 외장에 세련된 외관과 향상된 내구성을 부여하며, 기술의 가치를 높입니다.",
    ImageURL: samsung_sdi,
  },
];
